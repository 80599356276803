<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>Form Loyalty</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Limit Loyalty</label>
          <money v-model="form.loyalty_limit" class="form-control"></money>
        </div>
        <div class="form-group">
          <label>Is Repeat ?</label>
          <select class="form-control" v-model="form.loyalty_repeated">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div class="form-group" v-show="form.loyalty_repeated == '1'">
          <label>Periodic</label>
          <select class="form-control" v-model="form.status_repeated">
            <option value="">--Select Periodic--</option>
            <option
              v-for="(item, index) in listRepeated"
              :key="index"
              :value="index"
              >{{ item }}
            </option>
          </select>
          <small class="text-danger" v-if="errors.status_repeated">{{
            errors.status_repeated[0]
          }}</small>
        </div>
        <div class="form-group">
          <label>Expiry</label>
          <input type="date" class="form-control" v-model="form.ext_loyalty" />
        </div>
        <div class="form-group text-right">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="isSubmitting"
            @click="updateMember($event)"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../../services/api";
export default {
  data() {
    return {
      isSubmitting: false,
      listRepeated: [],
      form: {
        loyalty_limit: 0,
        loyalty_repeated: "0",
        status_repeated: "",
        ext_loyalty: "",
      },
      errors: [],
    };
  },
  mounted() {
    this.getListRepeated();
  },
  methods: {
    getListRepeated() {
      apiGet("get_user_application/get_list_repeated").then((res) => {
        this.listRepeated = res.data;
      });
    },
    updateMember() {
      this.isSubmitting = true;
      apiPost("get_user_application/update/" + this.$route.params.id, this.form)
        .then(() => {
          this.$parent.getData();
          this.isSubmitting = false;
          this.$noty.success("Data successfully added!");
          this.form = {
            loyalty_repeated: "0",
            loyalty_limit: 0,
            ext_loyalty: "",
            status_repeated: "",
          };
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
